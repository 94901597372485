import React from "react";
import Head from "./Head";
import "./App.css";
import img from "./assets/1.jpg";
import v1 from "./assets/v1.jpg";
import v2 from "./assets/v2.jpg";
import v3 from "./assets/v3.jpg";
import v4 from "./assets/v4.jpg";

const destinations = [
  {
    id: 1,
    name: "Omrah",
    image: img,
    description: "Répondez à l'appel sacré  de l'Omrah, et laissez votre cœur se purifier dans les lieux les plus bénis.",
    link: "/booking-omrah",
  },
  {
    id: 2,
    name: "VISA : EMIRATE / EGYPT / SAUDIA",
    image: v1,
    description: "Explorez Dubaï, l'Égypte et l'Arabie Saoudite avec des offres exceptionnelles.",
    link: "/visa1",
  },
  {
    id: 3,
    name: "VISA : BAHRAïN / QUATAR",
    image: v2,
    description: "Découvrez Bahreïn et le Qatar avec des offres spéciales.",
    link: "/visa2",
  },
  {
    id: 4,
    name: "VISA : CHINE / SINGAPORE",
    image: v3,
    description: "Explorez la Chine et Singapour avec des promotions exclusives.",
    link: "/visa3",
  },
  {
    id: 5,
    name: "VISA : ARMENIA",
    image: v4,
    description: "Découvrez l'Arménie avec des offres exclusives.",
    link: "/visa4",
  },

  // Add more destinations as needed
];

const Offres = () => {
  return (
    <>
      <header>
        <Head />
      </header>
      <main>
        <div
          style={{
            position: "relative",
            zIndex: 20,
            maxWidth: "1200px",
            margin: "0 auto",
            marginTop: "10%",
            padding: "0 1rem",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
            color: "#333",
            height: "100%",
            textShadow: "2px 2px 4px rgba(0, 0, 0, 0.3)",
          }}
        >
          <h1
            style={{
              fontSize: "2.25rem", // 4xl
              fontWeight: "bold",
              letterSpacing: "-0.015em", // tracking-tight
              margin: "1rem 0",
              paddingBottom: "0.5rem",
              fontFamily: "Arial, sans-serif",
            }}
          >
            OFFRES DE VOYAGES EXCLUSIVES
          </h1>
        </div>

        <div className="cardContainer">
          {destinations.map((destination) => (
            <div key={destination.id} className="card">
              <img src={destination.image} alt={destination.name} />
              <h2>{destination.name}</h2>
              <p>{destination.description}</p>
              <a href={destination.link}>RESERVER</a>
            </div>
          ))}
        </div>
      </main>
    </>
  );
};

export default Offres;
