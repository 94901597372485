import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import video from './assets/cover.mp4';
import hotel from "./assets/hotel.png";
import transport from "./assets/arret-de-bus.png";
import visa from "./assets/visa.png";
import touristic from "./assets/touristique.png";
import plane from "./assets/avion.png";
import encadrement from "./assets/direction.png";
import congree from "./assets/senat.png";
import kaaba from "./assets/kaaba.png";
import billet from "./assets/billet.png";
import nord from "./assets/nord.jpg";
import nationaux from "./assets/national.mp4";
import internationaux from "./assets/international.mp4";
import mediter from "./assets/mediter.jpg";
import atlantic from "./assets/centre atlantique.jpg";
// import serv from "./assets/services.png";
import fes from "./assets/fes.jpg";
import atlas from "./assets/atlas.jpg";
import sahara from "./assets/sahara.jpg";
import nafrica from "./assets/nafrica.jpg";
import africa from "./assets/africa.webp";
import moyeno from "./assets/moyeno.jpg";
import europe from "./assets/europe.jpg";
import asie from "./assets/asie.jpg";
import samerica from "./assets/samerica.jpg";
import prices from "./assets/tarifs.png";
import pelerinage from "./assets/pelerinage.mp4";
import hadj from "./assets/hadj.jpg";
import omrah from "./assets/omra.jpg";
import whatsapp from "./assets/whatsapp.png";
import cont from "./assets/contclient.png";
import tarifs from "./TARIFS.pdf";
import "./App.css";
import Head from "./Head.js";
import { Link } from "react-router-dom";
import Loading from "./loading.js";

function Main() {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 2000); //  2 seconds delay
  }, []);
  useEffect(() => {
    const scrollToTop = () => {
      window.scrollTo(0, 0);
    };
    scrollToTop();
  }, []);

 

  const [showParagraphs, setShowParagraphs] = useState({
    plane: false,
    encadrement: false,
    visa: false,
    touristic: false,
    transport: false,
    hotel: false,
    billeterie: false,
    hadj: false,
    mice: false,
  });

  const toggleParagraph = (service) => {
    setShowParagraphs((prevState) => ({
      ...prevState,
      [service]: !prevState[service],
    }));
  };

  //

  const faqData = [
    {
      question: "Quels services SN Travel propose-t-il ?",
      answer:
        "SN Travel propose une variété de services incluant la réservation de vols, de chambres d'hôtel, d'arrangements de transport, d'assistance visa, de visites de sites touristiques, et plus encore.",
    },
    {
      question: "Comment puis-je contacter SN Travel ?",
      answer:
        "Vous pouvez contacter SN Travel en remplissant le formulaire de contact, en appelant le numéro de téléphone fourni, ou en utilisant le lien WhatsApp.",
    },
    {
      question: "SN Travel propose-t-il des forfaits pour le Hajj et l'Omra ?",
      answer:
        "Oui, SN Travel propose des forfaits pour le Hajj et l'Omra. Vous pouvez trouver plus d'informations sur la page 'Hadj et Omra'.",
    },
    {
      question: "Comment puis-je vérifier les tarifs de SN Travel ?",
      answer:
        "Vous pouvez vérifier les tarifs de SN Travel en visitant la page 'Tarifs' ou en contactant directement SN Travel.",
    },
  ];

  function FAQItem({ question, answer }) {
    const [isOpen, setIsOpen] = useState(false);

    return (
      <div className="faq-item">
        <div className="question" onClick={() => setIsOpen(!isOpen)}>
          {question}
          <i className={`fa fa-chevron-down ${isOpen ? "open" : ""}`}></i>
        </div>
        {isOpen && <div className="answer">{answer}</div>}
      </div>
    );
  }

  function FAQSection({ faqData }) {
    return (
      <div className="faq-section">
        <h2>Questions Fréquement Posées</h2>
        {faqData.map((item, index) => (
          <FAQItem key={index} {...item} />
        ))}
      </div>
    );
  }

  //

  const testimonials = [
    {
      id: 1,
      name: "GHITA",
      text: "Une agence de confiance avec une équipe accueillante et sérieuse je vous souhaite une bonne continuation. j'espère voyager encore avec vous plusieurs fois.",
      rating: 5,
    },
    {
      id: 2,
      name: "IHSANE",
      text: "Une agréable expérience, le voyage était magnifique et surtout l’organisation était au top. Je recommande vivement ✅",
      rating: 5,
    },
    {
      id: 3,
      name: "ABDELHADI",
      text: "Mashallah, une agence distinguée et des services haut de gamme. Je les ai accompagnés pour la Omra et ce fut une expérience merveilleuse, Dieu soit loué. Je le recommande à tous les frères.",
      rating: 5,
    },
    {
      id: 4,
      name: "AYMAN",
      text: "L'agence Al-Sarifi Al-Nali a le meilleur service 👍🏻",
      rating: 5,
    },
    {
      id: 5,
      name: "IMANE",
      text: "Cette agence est l'une des meilleures agences avec lesquelles j'ai eu affaire, non seulement en termes de traitement et d'accueil des pèlerins et de logement, mais aussi pour l'accomplissement de la Omra dans la meilleure atmosphère. Oh mon Dieu, accorde-nous l'opportunité d'accomplir le Hajj du Sacré. Maison de Dieu 🤲🏻",
      rating: 5,
    },
    {
      id: 6,
      name: "MUSTAPHA",
      text: "Cette agence est l'une des meilleures avec lesquelles j'ai eu affaire, non seulement en termes de gestion et d'accueil des pèlerins et de logement, mais aussi pour l'exécution de la Omra dans la meilleure atmosphère.",
      rating: 5,
    },
  ];

  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: true,
    autoplay: true,
    autoplaySpeed: 3000,
  };

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <div>
          <Head />

          <div className="accueil" id="Accueil">
                  <video autoPlay loop muted className="slideVideo" >
                    <source src={video} type="video/mp4" />
                    Your browser does not support the video tag.
                  </video>          </div>




                  <div className="nservvv">
            {/* <img src={serv} alt="services" className="servv"/> */}
            <h1 className=" nservices" id="services">
              Nos services
            </h1>
          </div>

          <div className="services">
            <div className="servi">
              <img alt="hadj" src={kaaba} className="imgs" /> <br />
              <p className="sdescr">Hadj et Omra </p>
              {showParagraphs.hadj && (
                <p>
                  Découvrez la paix et la sérénité lors de votre Hadj ou Omra
                  avec SN Travel. Nous vous accompagnons à chaque étape de ce
                  voyage sacré, en veillant à ce que votre expérience soit aussi
                  confortable que possible.
                </p>
              )}
              <button
                className="voir-plus"
                onClick={() => toggleParagraph("hadj")}
              >
                {showParagraphs.hadj ? "Réduire" : "Voir plus"}
              </button>
            </div>
            <div className="servi">
              <img alt="accompagnement" src={encadrement} className="imgs" />{" "}
              <br />
              <p className="sdescr">Accompagnement </p>
              {showParagraphs.encadrement && (
                <p>
                  Notre équipe d'accompagnement dédiée est là pour rendre votre
                  voyage aussi confortable et sans souci que possible. Nous nous
                  occupons de tous les détails, de la planification à
                  l'assistance sur place.
                </p>
              )}
              <button
                className="voir-plus"
                onClick={() => toggleParagraph("encadrement")}
              >
                {showParagraphs.encadrement ? "Réduire" : "Voir plus"}
              </button>
            </div>

            <div className="servi">
              <img alt="hôtel" src={billet} className="imgs" /> <br />
              <p className="sdescr">Billeterie </p>
              {showParagraphs.billeterie && (
                <p>
                  Notre service de billeterie vous permet de réserver facilement
                  tous les aspects de votre voyage. Des billets d'avion aux
                  billets de bateau, en passant par les réservations d'hôtel et
                  de restaurant, nous vous offrons un guichet unique pour toutes
                  vos besoins.{" "}
                </p>
              )}
              <button
                className="voir-plus"
                onClick={() => toggleParagraph("billeterie")}
              >
                {showParagraphs.billeterie ? "Réduire" : "Voir plus"}
              </button>
            </div>

            <div className="servi">
              <img alt="avion" src={plane} className="imgs" /> <br />
              <p className="sdescr"> Réservation de Vols </p>
              {showParagraphs.plane && (
                <p>
                  Profitez d'une expérience de réservation de vols fluide avec
                  SN Travel. Nous vous offrons une large gamme de destinations,
                  des tarifs compétitifs et un service clientèle exceptionnel.
                </p>
              )}
              <button
                className="voir-plus"
                onClick={() => toggleParagraph("plane")}
              >
                {showParagraphs.plane ? "Réduire" : "Voir plus"}
              </button>
            </div>

            <div className="servi">
              <img alt="sites touristiques" src={touristic} className="imgs" />{" "}
              <br />
              <p className="sdescr">Sites Touristiques </p>
              {showParagraphs.touristic && (
                <p>
                  Explorez les merveilles du monde avec nos circuits
                  touristiques soigneusement planifiés. Des destinations
                  emblématiques aux trésors cachés, nous vous offrons des
                  expériences inoubliables.
                </p>
              )}
              <button
                className="voir-plus"
                onClick={() => toggleParagraph("touristic")}
              >
                {showParagraphs.touristic ? "Réduire" : "Voir plus"}
              </button>
            </div>

            <div className="servi">
              <img alt="visa" src={visa} className="imgs" /> <br />
              <p className="sdescr">Processus de Visa </p>
              {showParagraphs.visa && (
                <p>
                  Laissez-nous vous aider à naviguer dans le processus parfois
                  complexe d'obtention d'un visa. Notre équipe expérimentée vous
                  guidera à chaque étape, vous assurant une expérience sans
                  tracas.
                </p>
              )}
              <button
                className="voir-plus"
                onClick={() => toggleParagraph("visa")}
              >
                {showParagraphs.visa ? "Réduire" : "Voir plus"}
              </button>
            </div>

            <div className="servi">
              <img alt="transport" src={transport} className="imgs" /> <br />
              <p className="sdescr">Transport Confortable </p>
              {showParagraphs.transport && (
                <p>
                  Profitez d'un transport confortable et fiable tout au long de
                  votre voyage avec SN Travel. Que ce soit en voiture, en train
                  ou en bus, nous nous assurons que vous voyagez en toute
                  tranquillité.
                </p>
              )}
              <button
                className="voir-plus"
                onClick={() => toggleParagraph("transport")}
              >
                {showParagraphs.transport ? "Réduire" : "Voir plus"}
              </button>
            </div>

            <div className="servi">
              <img alt="hôtel" src={hotel} className="imgs" /> <br />
              <p className="sdescr">Des Hôtels de Qualité </p>
              {showParagraphs.hotel && (
                <p>
                  Retrouvez le confort de la maison dans nos hôtels de qualité
                  sélectionnés avec soin. Que vous recherchiez un hébergement de
                  luxe ou une expérience authentique, nous avons l'option
                  parfaite pour vous.
                </p>
              )}
              <button
                className="voir-plus"
                onClick={() => toggleParagraph("hotel")}
              >
                {showParagraphs.hotel ? "Réduire" : "Voir plus"}
              </button>
            </div>

            <div className="servi">
              <img alt="Mice" src={congree} className="imgs" /> <br />
              <p className="sdescr">Mice</p>
              {showParagraphs.mice && (
                <p>
                  Nous organisons des événements d'entreprise complets avec
                  notre service MICE. Nous gérons la planification, la
                  logistique et la coordination pour des séminaires,
                  conférences, incentives et expositions.{" "}
                </p>
              )}
              <button
                className="voir-plus"
                onClick={() => toggleParagraph("mice")}
              >
                {showParagraphs.mice ? "Réduire" : "Voir plus"}
              </button>
            </div>
          </div>



          
          <div style={{ height: "100px" }}>
            <h1 className="acctxt" id="destinations">
              DECOUVREZ VOS DESTINATIONS DE REVES AVEC SN TRAVEL !
            </h1>
          </div>
          <br />

          <div className="destinations">
            <div className="mrc" id="pelerinage">
              <video autoPlay muted>
                <source src={pelerinage} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </div>
            <div className="mar">
              <div className="dcards">
                <Link to="/Hadj">
                  <img src={hadj} alt="pict" className="nimg" />
                </Link>
                <h2>Hadj</h2>
                <p>
                  Le Hajj, pilier de l'islam, attire chaque année des millions
                  de pèlerins à La Mecque pour des rituels sacrés, offrant une
                  expérience spirituelle profonde et une immersion dans la
                  communauté musulmane mondiale. Cette expérience transcende les
                  frontières culturelles, laissant souvent une empreinte
                  spirituelle durable sur les participants.
                </p>
              </div>

              <div className="dcards">
                <Link to="/Omrah">
                  {" "}
                  <img src={omrah} alt="pict" className="nimg" />
                </Link>
                <h2>Omra</h2>
                <p>
                  L'Omra, également appelé "petit pèlerinage", offre une
                  expérience spirituelle profonde à tout moment de l'année pour
                  les touristes musulmans, permettant de se connecter avec la
                  foi en visitant des lieux saints comme La Mecque et Médine,
                  offrant des rituels religieux significatifs et une immersion
                  dans la richesse culturelle et historique de la région, créant
                  ainsi des liens spirituels durables avec d'autres pèlerins du
                  monde entier.
                </p>
              </div>
            </div>
          </div>

          <div className="mrc" id="nationaux">
            <video autoPlay muted>
              <source src={nationaux} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
            <br />
          </div>

          <div className="destinations">
            <div className="mar">
              <div className="dcards">
                <Link to="/Nord_du_Maroc">
                  {" "}
                  <img src={nord} alt="pict" className="nimg" />
                </Link>
                <h2>Nord du Maroc</h2>
                <p>
                  Le Nord du Maroc, avec sa diversité culturelle et ses paysages
                  spectaculaires, de Tanger à Chefchaouen, offre une expérience
                  authentique aux voyageurs, entre charme historique, ruelles
                  bleues pittoresques et plages de sable fin le long de la côte
                  méditerranéenne, invitant à la découverte d'histoires uniques
                  et de trésors naturels, faisant de cette région un joyau à
                  explorer pour des souvenirs impérissables.
                </p>
              </div>

              <div className="dcards">
                <Link to="/Maroc-mediterranee">
                  {" "}
                  <img src={mediter} alt="pict" className="nimg" />
                </Link>
                <h2>Maroc Mediterranee</h2>
                <p>
                  Le Rif, au Maroc, fascine les voyageurs avec ses paysages
                  montagneux spectaculaires, ses villages berbères pittoresques
                  et ses vallées verdoyantes, offrant des aventures mémorables
                  pour les amateurs de randonnée. En explorant ses marchés
                  animés, en savourant sa cuisine traditionnelle et en
                  rencontrant ses habitants chaleureux, les touristes peuvent
                  plonger dans la richesse culturelle de la région.
                </p>
              </div>

              {/*  */}
              <div className="dcards">
                <Link to="/Centre-atlantique-du-Maroc">
                  {" "}
                  <img src={atlantic} alt="pict" className="nimg" />
                </Link>
                <h2>Centre atlantique du Maroc</h2>
                <p>
                  Le Centre atlantique du Maroc, avec des joyaux historiques
                  comme Rabat, Meknès et les ruines de Volubilis, ainsi que des
                  plages paisibles le long de la côte, offre une expérience
                  riche en histoire, culture et nature. Ses médinas animées, ses
                  palais majestueux et ses paysages côtiers préservés, associés
                  à une cuisine savoureuse et une hospitalité légendaire, en
                  font une destination idéale pour les voyageurs à la recherche
                  d'aventure, de culture et de détente dans un cadre naturel
                  incomparable.
                </p>
              </div>

              <div className="dcards">
                <Link to="/Centre_du_Maroc">
                  <img src={fes} alt="pict" className="nimg" />
                </Link>
                <h2>Centre du Maroc</h2>
                <p>
                  Le centre du Maroc, incluant des villes telles que Fès et
                  Meknès, est une région d'une richesse exceptionnelle en
                  histoire, culture et architecture. Fès attire par ses médinas
                  pittoresques et ses souks animés, tandis que Meknès séduit
                  avec ses impressionnants remparts et ses palais majestueux. La
                  région est également réputée pour sa cuisine délicieuse, ses
                  festivals colorés et ses oasis verdoyantes, offrant aux
                  voyageurs une immersion authentique au cœur du Maroc.
                </p>
              </div>
              <br />

              <div className="dcards">
                <Link to="/Atlas_du_Maroc">
                  {" "}
                  <img src={atlas} alt="pict" className="nimg" />
                </Link>
                <h2>Atlas du Maroc</h2>
                <p>
                  L'Atlas et les vallées du Maroc offrent une expérience mêlant
                  nature et culture, avec des montagnes majestueuses et des
                  villages berbères traditionnels, ainsi que des vallées
                  fertiles abritant des trésors naturels comme des cascades et
                  des gorges, promettant des aventures inoubliables pour les
                  voyageurs en quête d'aventure et de détente.
                </p>
              </div>

              <div className="dcards">
                <Link to="/Sud_du_Maroc">
                  <img src={sahara} alt="pict" className="nimg" />
                </Link>
                <h2>Sud du Maroc </h2>
                <p>
                  Le sud du Maroc offre une expérience envoûtante avec ses
                  paysages divers, allant des déserts aux oasis verdoyantes et
                  aux villages traditionnels berbères, témoignant d'un riche
                  patrimoine culturel et offrant une hospitalité chaleureuse, ce
                  qui en fait une destination prisée pour les voyageurs en quête
                  d'aventure et d'authenticité.
                </p>
              </div>
            </div>
            <br />

            <div className="mrc" id="internationaux">
              <video autoPlay muted>
                <source src={internationaux} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </div>

            <div className="mar">
              <div className="dcards">
                <Link to="/Afrique-du-nord">
                  <img src={nafrica} alt="pict" className="nimg" />
                </Link>
                <h2>Afrique Du Nord</h2>
                <p>
                  L'Afrique du Nord, avec ses paysages envoûtants et son riche
                  patrimoine culturel, est une destination incontournable pour
                  les voyageurs en quête d'aventure et de découverte. Des
                  médinas animées de Marrakech aux majestueuses pyramides
                  d'Égypte, chaque coin de la région offre une expérience
                  unique, où les voyageurs peuvent se perdre dans les ruelles
                  étroites des anciennes médinas, goûter à la cuisine locale et
                  explorer les vestiges des civilisations anciennes.
                </p>
              </div>

              <div className="dcards">
                <Link to="/Afrique">
                  <img src={africa} alt="pict" className="nimg" />
                </Link>
                <h2>Afrique</h2>
                <p>
                  L'Afrique, le berceau de l'humanité, séduit par sa beauté et
                  sa diversité uniques, offrant aux voyageurs une expérience
                  inoubliable. Du Sahara majestueux aux vastes plaines de la
                  savane, abritant une faune extraordinaire, en passant par la
                  richesse culturelle des anciennes civilisations et la cuisine
                  exotique, chaque aspect de ce continent promet des aventures
                  inoubliables et laisse une empreinte durable dans le cœur des
                  visiteurs.
                </p>
              </div>

              <div className="dcards">
                <Link to="/Moyen-orient">
                  {" "}
                  <img src={moyeno} alt="pict" className="nimg" />
                </Link>
                <h2>Moyen-Orient & Pays Du Golfe</h2>
                <p>
                  Le Moyen-Orient, avec des destinations comme le Qatar, les
                  Émirats arabes unis et l'Arabie saoudite, offre une richesse
                  culturelle et des attractions uniques. Des gratte-ciel
                  futuristes de Dubai aux sites historiques de La Mecque, cette
                  région présente un mélange captivant de modernité et de
                  traditions, invitant les voyageurs à une immersion dans un
                  monde de contrastes entre tradition et innovation.
                </p>
              </div>

              <div className="dcards">
                <Link to="/Europe">
                  {" "}
                  <img src={europe} alt="pict" className="nimg" />
                </Link>
                <h2>Europe</h2>
                <p>
                  L'Europe, continent d'une incroyable diversité, séduit les
                  voyageurs avec ses expériences culturelles, historiques et
                  naturelles variées. Des musées renommés de Rome aux paysages
                  des Alpes suisses, en passant par les villes dynamiques comme
                  Londres et les villages pittoresques de la Méditerranée,
                  chaque coin de l'Europe raconte une histoire unique, offrant
                  ainsi un voyage pour tous les goûts et toutes les envies.
                </p>
              </div>

              <div className="dcards">
                <Link to="/Asie">
                  <img src={asie} alt="pict" className="nimg" />
                </Link>
                <h2>Asie</h2>
                <p>
                  L'Asie, continent fascinant, offre une diversité incroyable à
                  explorer, des temples ancestraux de l'Inde aux gratte-ciel
                  scintillants de Singapour, en passant par les marchés animés
                  de Bangkok et les plages de sable blanc de la Thaïlande. Avec
                  ses cultures riches et ses merveilles naturelles, comme les
                  forêts luxuriantes de l'Indonésie, l'Asie promet des voyages
                  inoubliables empreints d'émerveillement et de découverte.
                </p>
              </div>

              <div className="dcards">
                <Link to="/South-America">
                  {" "}
                  <img src={samerica} alt="pict" className="nimg" />
                </Link>
                <h2>Amerique Du Sud</h2>
                <p>
                  L'Amérique du Sud, continent aux multiples facettes, offre une
                  diversité incroyable de paysages, de cultures et d'aventures,
                  des sommets enneigés des Andes aux plages paradisiaques de la
                  côte brésilienne, promettant des expériences inoubliables pour
                  les voyageurs en quête d'aventure, de découverte et de
                  dépaysement.
                </p>{" "}
              </div>
            </div>
          </div>

          <div className="tarifs" id="tarifs">
            <img src={prices} alt="world" />
            <h3 className="ptar">
              Chez SN TRAVEL, nous vous proposons des prix imbattables pour vous
              permettre de vivre des expériences extraordinaires sans vous
              ruiner.
            </h3>
            <br />
            <a
              className="tarifsb"
              href={tarifs}
              target="_blank"
              rel="noopener noreferrer"
            >
              CONSULTER NOS TARIFS
            </a>
          </div>
          {/*  */}
          <div className="testimonials" id="AVIS">
            <h2 className="testimonial-title">AVIS DES CLIENTS</h2>
            <Slider {...sliderSettings}>
              {testimonials.map((testimonial) => (
                <div key={testimonial.id} className="testimonial-item">
                  <p className="testimonial-text">{testimonial.text}</p>
                  <p className="testimonial-name">- {testimonial.name}</p>
                  <div className="testimonial-rating">
                    {Array(testimonial.rating)
                      .fill()
                      .map((_, i) => (
                        <span key={i} className="star">
                          ⭐
                        </span>
                      ))}
                  </div>
                </div>
              ))}
            </Slider>
          </div>
          <br />
          <div class="section-cta">
            <h2>Prêt à explorer le monde ?</h2>
            <p>
              Que vous cherchiez une escapade relaxante, un voyage aventureux ou
              une immersion culturelle, nous avons le forfait de voyage idéal
              pour vous. Choisissez parmi nos destinations sélectionnées, ou
              laissez-nous personnaliser votre voyage selon vos préférences et
              votre budget.
            </p>
            <p>
              Ne manquez pas cette opportunité de découvrir de nouveaux
              endroits, de rencontrer de nouvelles personnes et de créer des
              souvenirs inoubliables. Réservez votre voyage dès aujourd'hui et
              bénéficiez d'une réduction de 5% sur votre première réservation.
            </p>
            <div class="boutons-cta">
              <a href="/OFFRES" class="bouton-cta">
                RESERVEZ
              </a>
            </div>
          </div>

          <br />

          <FAQSection faqData={faqData} />
          <br />

          <a href="https://api.whatsapp.com/send/?phone=212663290166&text&type=phone_number&app_absent=0">
            {" "}
            <img src={whatsapp} alt="whatsapp" className="whatsapp" />{" "}
          </a>
          <a href="/contact ">
            {" "}
            <img src={cont} alt="contact" className="contactic" />{" "}
          </a>
        </div>
      )}
    </>
  );
}

export default Main;
