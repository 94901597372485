import emailjs from "@emailjs/browser";
import { useEffect, useRef, useState } from "react";
import React from "react";
import Head from "./Head";
import omrahp from "./assets/o1.jpg";
// import v1 from "./assets/inv1.jpg";

export const BookingOmrah = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    tel: "",
    codeTel: "+212", // Default value
    purpose: "omrah", // Default value
    message: "",
    hotel: "",
    roomType: "",
  });

  const [showSuccessPopup, setShowSuccessPopup] = useState(false);
  const [error, setError] = useState("");

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_zx1ebxt",
        "template_gmn4v6o",
        e.target,
        "DPpmV3XbxNyPUT3_P"
      )
      .then(
        (result) => {
          console.log("Email successfully sent!", result.text);
          setShowSuccessPopup(true); // Show popup on successful email send
          // Reset form fields after successful submission
          setFormData({
            name: "",
            email: "",
            tel: "",
            codeTel: "+212", // Reset to default
            purpose: "omrah", // Reset to default
            message: "",
            hotel: "",
            roomType: "",
          });
        },
        (error) => {
          console.error("Email sending failed:", error.text);
          setError("Failed to send message. Please try again later."); // Display error to user
        }
      );
  };

  return (
    <>
      <div className="resimgc">
        <img src={omrahp} alt="contact" className="resimg" />
      </div>
      <Head />

      <h2 className="contactez" id="contact">
        RESERVEZ
      </h2>
      <div className="contact">
        <form onSubmit={sendEmail} ref={form}>
          <label htmlFor="name">NOM:</label>
          <input
            type="text"
            id="name"
            name="name"
            placeholder="VOTRE NOM COMPLET"
            value={formData.name}
            onChange={handleChange}
            required
          />
          <br />
          <label htmlFor="email">EMAIL:</label>
          <input
            type="email"
            id="email"
            name="email"
            placeholder="VOTRE EMAIL"
            value={formData.email}
            onChange={handleChange}
          />
          <br />
          <label htmlFor="tel">NUMERO DE TELEPHONE:</label>
          <div style={{ display: "flex", flexDirection: "row", width: "111%" }}>
            <select
              style={{ width: "25%" }}
              name="codeTel"
              value={formData.codeTel}
              onChange={handleChange}
              required
            >
              <option value="+212">(+212) Maroc</option>
              <option value="+213">(+213) Algérie</option>
              <option value="+216">(+216) Tunisie</option>
              <option value="+218">(+218) Libye</option>
              <option value="+20">(+20) Égypte</option>
              <option value="+30">(+30) Grèce</option>
              <option value="+31">(+31) Pays-Bas</option>
              <option value="+32">(+32) Belgique</option>
              <option value="+33">(+33) France</option>
              <option value="+34">(+34) Espagne</option>
              <option value="+39">(+39) Italie</option>
              <option value="+44">(+44) Royaume-Uni</option>
              <option value="+49">(+49) Allemagne</option>
              <option value="+1">(+1) États-Unis/Canada</option>
              <option value="+52">(+52) Mexique</option>
              <option value="+54">(+54) Argentine</option>
              <option value="+55">(+55) Brésil</option>
            </select>
            <input
              type="text"
              className="destinatf"
              id="tel"
              name="tel"
              value={formData.tel}
              pattern="[0-9]*"
              title="Le numéro de téléphone doit contenir uniquement des chiffres"
              onChange={handleChange}
              placeholder="VOTRE NUMERO DE TELEPHONE"
              style={{ width: "65%" }}
              required
            />
          </div>
          <br />
          <label htmlFor="purpose">DESTINATIONS:</label>
          <select
            className="destinatf"
            id="purpose"
            name="purpose"
            value={formData.purpose}
            onChange={handleChange}
            required
          >
            <option value="omrah">OMRAH</option>
          </select>
          <br />
          <label htmlFor="hotel">HOTEL:</label>
          <select
            id="hotel"
            name="hotel"
            value={formData.hotel}
            onChange={handleChange}
            required
          >
            <option value="ABRAJ AL KISWA">ABRAJ AL KISWA</option>
            <option value="SARAYA DIAFA">SARAYA DIAFA</option>
            <option value="ANJUM">ANJUM</option>
            <option value="SUISSOTEL">SUISSOTEL</option>
          </select>
          <br />
          <label htmlFor="roomType">CHAMBRE:</label>
          <select
            id="roomType"
            name="roomType"
            value={formData.roomType}
            onChange={handleChange}
            required
          >
            <option value="double">Double</option>
            <option value="pour 3 personnes">pour 3 personnes</option>
            <option value="pour 4 personnes">pour 4 personnes</option>
            <option value="pour 5 personnes">pour 5 personnes</option>
          </select>
          <br />
          <label htmlFor="message">MESSAGE:</label>
          <textarea
            name="message"
            id="message"
            placeholder="ECRIVEZ VOTRE MESSAGE"
            value={formData.message}
            onChange={handleChange}
            style={{ borderRadius: "7px" }}
          ></textarea>
          <input type="submit" value="Envoyez" className="button" />
        </form>
      </div>

      {showSuccessPopup && (
        <div className="success-popup">
          <p>
            Message envoyé avec succès. Nous vous répondrons dès que possible.
          </p>
        </div>
      )}

      {error && (
        <div className="error-popup">
          <p>{error}</p>
        </div>
      )}
    </>
  );
};
export const BookingVisa1 = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    tel: "",
    codeTel: "+212", // Default value
    message: "",
    pays: "",
  });

  const [showSuccessPopup, setShowSuccessPopup] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_xwo1nkg",
        "template_173o5pm",
        e.target,
        "u1vV_5wxERJS3ecIC"
      )
      .then(
        (result) => {
          console.log("Email successfully sent!", result.text);
          setShowSuccessPopup(true); // Show popup on successful email send
          // Reset form fields after successful submission
          setFormData({
            name: "",
            email: "",
            tel: "",
            codeTel: "+212", // Reset to default
            message: "",
            pays: "",
          });
        },
        (error) => {
          console.error("Email sending failed:", error.text);
          // Optionally set error state here for user feedback
        }
      );
  };

  return (
    <>
      <Head />
      {/* <div>
        <img src={v1} alt="contact" className="contactimg" />
      </div> */}
      <h2 className="contactez" id="contact">
        RESERVEZ
      </h2>
      <div className="contact">
        <form onSubmit={sendEmail} ref={form}>
          <label htmlFor="name">NOM:</label>
          <input
            type="text"
            id="name"
            name="name"
            placeholder="VOTRE NOM COMPLET"
            value={formData.name}
            onChange={handleChange}
            required
          />
          <br />
          <label htmlFor="email">EMAIL:</label>
          <input
            type="email"
            id="email"
            name="email"
            placeholder="VOTRE EMAIL"
            value={formData.email}
            onChange={handleChange}
          />
          <br />
          <label htmlFor="tel">NUMERO DE TELEPHONE:</label>
          <div style={{ display: "flex", flexDirection: "row", width: "111%" }}>
            <select
              style={{ width: "23%" }}
              name="codeTel"
              value={formData.codeTel}
              onChange={handleChange}
              required
            >
              <option value="+212">(+212) Maroc</option>
              <option value="+213">(+213) Algérie</option>
              <option value="+216">(+216) Tunisie</option>
              <option value="+218">(+218) Libye</option>
              <option value="+20">(+20) Égypte</option>
              <option value="+30">(+30 ) Grèce</option>
              <option value="+31">(+31 ) Pays-Bas</option>
              <option value="+32">(+32 ) Belgique</option>
              <option value="+33">(+33 ) France</option>
              <option value="+34">(+34 ) Espagne</option>
              <option value="+39">(+39 ) Italie</option>
              <option value="+44">(+44 ) Royaume-Uni</option>
              <option value="+49">(+49 ) Allemagne</option>
              <option value="+1">(+1 ) États-Unis/Canada</option>
              <option value="+52">(+52 ) Mexique</option>
              <option value="+54">(+54 ) Argentine</option>
              <option value="+55">(+55 ) Brésil</option>{" "}
            </select>
            <input
              type="text"
              id="tel"
              name="tel"
              value={formData.tel}
              pattern="\d*"
              title="Le numéro de téléphone doit contenir uniquement des chiffres"
              onChange={handleChange}
              placeholder="VOTRE NUMERO DE TELEPHONE"
              style={{ width: "67%" }}
              required
            />
          </div>
          <br />
          <label htmlFor="pays">PAYS:</label>
          <select
            id="pays"
            name="pays"
            value={formData.pays}
            onChange={handleChange}
            required
          >
            <option value="visa EMIRATE ">EMIRATE</option>
            <option value="visa EGYPT  ">EGYPT</option>
            <option value="visa SAUDIA ">SAUDIA</option>
          </select>
          <br />
          <br />
          <label htmlFor="message">MESSAGE:</label>
          <textarea
            name="message"
            id="message"
            placeholder="ECRIVEZ VOTRE MESSAGE"
            value={formData.message}
            onChange={handleChange}
            style={{ borderRadius: "7px" }}
          ></textarea>
          <input type="submit" value="Envoyez" className="button" />
        </form>
      </div>

      {showSuccessPopup && (
        <div className="success-popup">
          <p>
            Message envoyé avec succès. Nous vous répondrons dès que possible.
          </p>
        </div>
      )}
    </>
  );
};

export const BookingVisa2 = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    tel: "",
    codeTel: "+212", // Default value
    message: "",
    pays: "",
  });

  const [showSuccessPopup, setShowSuccessPopup] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_xwo1nkg",
        "template_173o5pm",
        e.target,
        "u1vV_5wxERJS3ecIC"
      )
      .then(
        (result) => {
          console.log("Email successfully sent!", result.text);
          setShowSuccessPopup(true); // Show popup on successful email send
          // Reset form fields after successful submission
          setFormData({
            name: "",
            email: "",
            tel: "",
            codeTel: "+212", // Reset to default
            message: "",
            pays: "",
          });
        },
        (error) => {
          console.error("Email sending failed:", error.text);
          // Optionally set error state here for user feedback
        }
      );
  };

  return (
    <>
      <Head />

      <h2 className="contactez" id="contact">
        RESERVEZ
      </h2>
      <div className="contact">
        <form onSubmit={sendEmail} ref={form}>
          <label htmlFor="name">NOM:</label>
          <input
            type="text"
            id="name"
            name="name"
            placeholder="VOTRE NOM COMPLET"
            value={formData.name}
            onChange={handleChange}
            required
          />
          <br />
          <label htmlFor="email">EMAIL:</label>
          <input
            type="email"
            id="email"
            name="email"
            placeholder="VOTRE EMAIL"
            value={formData.email}
            onChange={handleChange}
          />
          <br />
          <label htmlFor="tel">NUMERO DE TELEPHONE:</label>
          <div style={{ display: "flex", flexDirection: "row", width: "111%" }}>
            <select
              style={{ width: "23%" }}
              name="codeTel"
              value={formData.codeTel}
              onChange={handleChange}
              required
            >
              <option value="+212">(+212) Maroc</option>
              <option value="+213">(+213) Algérie</option>
              <option value="+216">(+216) Tunisie</option>
              <option value="+218">(+218) Libye</option>
              <option value="+20">(+20) Égypte</option>
              <option value="+30">(+30 ) Grèce</option>
              <option value="+31">(+31 ) Pays-Bas</option>
              <option value="+32">(+32 ) Belgique</option>
              <option value="+33">(+33 ) France</option>
              <option value="+34">(+34 ) Espagne</option>
              <option value="+39">(+39 ) Italie</option>
              <option value="+44">(+44 ) Royaume-Uni</option>
              <option value="+49">(+49 ) Allemagne</option>
              <option value="+1">(+1 ) États-Unis/Canada</option>
              <option value="+52">(+52 ) Mexique</option>
              <option value="+54">(+54 ) Argentine</option>
              <option value="+55">(+55 ) Brésil</option>{" "}
            </select>
            <input
              type="text"
              id="tel"
              name="tel"
              value={formData.tel}
              pattern="\d*"
              title="Le numéro de téléphone doit contenir uniquement des chiffres"
              onChange={handleChange}
              placeholder="VOTRE NUMERO DE TELEPHONE"
              style={{ width: "67%" }}
              required
            />
          </div>
          <br />
          <label htmlFor="pays">PAYS:</label>
          <select
            id="pays"
            name="pays"
            value={formData.pays}
            onChange={handleChange}
            required
          >
            <option value="visa EMIRATE ">BAHRAïN</option>
            <option value="visa EGYPT  ">QUATAR</option>
          </select>
          <br />
          <label htmlFor="message">MESSAGE:</label>
          <textarea
            name="message"
            id="message"
            placeholder="ECRIVEZ VOTRE MESSAGE"
            value={formData.message}
            onChange={handleChange}
            style={{ borderRadius: "7px" }}
          ></textarea>
          <input type="submit" value="Envoyez" className="button" />
        </form>
      </div>

      {showSuccessPopup && (
        <div className="success-popup">
          <p>
            Message envoyé avec succès. Nous vous répondrons dès que possible.
          </p>
        </div>
      )}
    </>
  );
};

export const BookingVisa3 = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    tel: "",
    codeTel: "+212", // Default value
    message: "",
    pays: "",
  });

  const [showSuccessPopup, setShowSuccessPopup] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_xwo1nkg",
        "template_173o5pm",
        e.target,
        "u1vV_5wxERJS3ecIC"
      )
      .then(
        (result) => {
          console.log("Email successfully sent!", result.text);
          setShowSuccessPopup(true); // Show popup on successful email send
          // Reset form fields after successful submission
          setFormData({
            name: "",
            email: "",
            tel: "",
            codeTel: "+212", // Reset to default
            message: "",
            pays: "",
          });
        },
        (error) => {
          console.error("Email sending failed:", error.text);
          // Optionally set error state here for user feedback
        }
      );
  };

  return (
    <>
      <Head />

      <h2 className="contactez" id="contact">
        RESERVEZ
      </h2>
      <div className="contact">
        <form onSubmit={sendEmail} ref={form}>
          <label htmlFor="name">NOM:</label>
          <input
            type="text"
            id="name"
            name="name"
            placeholder="VOTRE NOM COMPLET"
            value={formData.name}
            onChange={handleChange}
            required
          />
          <br />
          <label htmlFor="email">EMAIL:</label>
          <input
            type="email"
            id="email"
            name="email"
            placeholder="VOTRE EMAIL"
            value={formData.email}
            onChange={handleChange}
          />
          <br />
          <label htmlFor="tel">NUMERO DE TELEPHONE:</label>
          <div style={{ display: "flex", flexDirection: "row", width: "111%" }}>
            <select
              style={{ width: "23%" }}
              name="codeTel"
              value={formData.codeTel}
              onChange={handleChange}
              required
            >
              <option value="+212">(+212) Maroc</option>
              <option value="+213">(+213) Algérie</option>
              <option value="+216">(+216) Tunisie</option>
              <option value="+218">(+218) Libye</option>
              <option value="+20">(+20) Égypte</option>
              <option value="+30">(+30 ) Grèce</option>
              <option value="+31">(+31 ) Pays-Bas</option>
              <option value="+32">(+32 ) Belgique</option>
              <option value="+33">(+33 ) France</option>
              <option value="+34">(+34 ) Espagne</option>
              <option value="+39">(+39 ) Italie</option>
              <option value="+44">(+44 ) Royaume-Uni</option>
              <option value="+49">(+49 ) Allemagne</option>
              <option value="+1">(+1 ) États-Unis/Canada</option>
              <option value="+52">(+52 ) Mexique</option>
              <option value="+54">(+54 ) Argentine</option>
              <option value="+55">(+55 ) Brésil</option>{" "}
            </select>
            <input
              type="text"
              id="tel"
              name="tel"
              value={formData.tel}
              pattern="\d*"
              title="Le numéro de téléphone doit contenir uniquement des chiffres"
              onChange={handleChange}
              placeholder="VOTRE NUMERO DE TELEPHONE"
              style={{ width: "67%" }}
              required
            />
          </div>
          <br />
          <label htmlFor="pays">PAYS:</label>
          <select
            id="pays"
            name="pays"
            value={formData.pays}
            onChange={handleChange}
            required
          >
            <option value="CHINA">CHINA</option>
            <option value="SINGAPORE">SINGAPORE</option>
          </select>
          <br />
          <br />
          <label htmlFor="message">MESSAGE:</label>
          <textarea
            name="message"
            id="message"
            placeholder="ECRIVEZ VOTRE MESSAGE"
            value={formData.message}
            onChange={handleChange}
            style={{ borderRadius: "7px" }}
          ></textarea>
          <input type="submit" value="Envoyez" className="button" />
        </form>
      </div>

      {showSuccessPopup && (
        <div className="success-popup">
          <p>
            Message envoyé avec succès. Nous vous répondrons dès que possible.
          </p>
        </div>
      )}
    </>
  );
};

export const BookingVisa4 = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    tel: "",
    codeTel: "+212", // Default value
    message: "",
    pays: "",
  });

  const [showSuccessPopup, setShowSuccessPopup] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_xwo1nkg",
        "template_173o5pm",
        e.target,
        "u1vV_5wxERJS3ecIC"
      )
      .then(
        (result) => {
          console.log("Email successfully sent!", result.text);
          setShowSuccessPopup(true); // Show popup on successful email send
          // Reset form fields after successful submission
          setFormData({
            name: "",
            email: "",
            tel: "",
            codeTel: "+212", // Reset to default
            message: "",
            pays: "",
          });
        },
        (error) => {
          console.error("Email sending failed:", error.text);
          // Optionally set error state here for user feedback
        }
      );
  };

  return (
    <>
      <Head />

      <h2 className="contactez" id="contact">
        RESERVEZ
      </h2>
      <div className="contact">
        <form onSubmit={sendEmail} ref={form}>
          <label htmlFor="name">NOM:</label>
          <input
            type="text"
            id="name"
            name="name"
            placeholder="VOTRE NOM COMPLET"
            value={formData.name}
            onChange={handleChange}
            required
          />
          <br />
          <label htmlFor="email">EMAIL:</label>
          <input
            type="email"
            id="email"
            name="email"
            placeholder="VOTRE EMAIL"
            value={formData.email}
            onChange={handleChange}
          />
          <br />
          <label htmlFor="tel">NUMERO DE TELEPHONE:</label>
          <div style={{ display: "flex", flexDirection: "row", width: "111%" }}>
            <select
              style={{ width: "23%" }}
              name="codeTel"
              value={formData.codeTel}
              onChange={handleChange}
              required
            >
              <option value="+212">(+212) Maroc</option>
              <option value="+213">(+213) Algérie</option>
              <option value="+216">(+216) Tunisie</option>
              <option value="+218">(+218) Libye</option>
              <option value="+20">(+20) Égypte</option>
              <option value="+30">(+30 ) Grèce</option>
              <option value="+31">(+31 ) Pays-Bas</option>
              <option value="+32">(+32 ) Belgique</option>
              <option value="+33">(+33 ) France</option>
              <option value="+34">(+34 ) Espagne</option>
              <option value="+39">(+39 ) Italie</option>
              <option value="+44">(+44 ) Royaume-Uni</option>
              <option value="+49">(+49 ) Allemagne</option>
              <option value="+1">(+1 ) États-Unis/Canada</option>
              <option value="+52">(+52 ) Mexique</option>
              <option value="+54">(+54 ) Argentine</option>
              <option value="+55">(+55 ) Brésil</option>{" "}
            </select>
            <input
              type="text"
              id="tel"
              name="tel"
              value={formData.tel}
              pattern="\d*"
              title="Le numéro de téléphone doit contenir uniquement des chiffres"
              onChange={handleChange}
              placeholder="VOTRE NUMERO DE TELEPHONE"
              style={{ width: "67%" }}
              required
            />
          </div>
          <br />
          <label htmlFor="pays">PAYS:</label>
          <select
            id="pays"
            name="pays"
            value={formData.pays}
            onChange={handleChange}
            required
          >
            <option value="ARMENIA">Armenia</option>
          </select>
          <br />
          <br />
          <label htmlFor="message">MESSAGE:</label>
          <textarea
            name="message"
            id="message"
            placeholder="ECRIVEZ VOTRE MESSAGE"
            value={formData.message}
            onChange={handleChange}
            style={{ borderRadius: "7px" }}
          ></textarea>
          <input type="submit" value="Envoyez" className="button" />
        </form>
      </div>

      {showSuccessPopup && (
        <div className="success-popup">
          <p>
            Message envoyé avec succès. Nous vous répondrons dès que possible.
          </p>
        </div>
      )}
    </>
  );
};
